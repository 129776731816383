.e-custom-fe-singleLineIcon {
  background-image: url("../../../../../assets/icons/svg/SingleLineTextBox.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}

.e-custom-fe-multiLineIcon {
  background-image: url("../../../../../assets/icons/svg/MultiLineTextBox.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}

.e-custom-fe-singleSelectIcon {
  background-image: url("../../../../../assets/icons/svg/RadioButtonIcon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}

.e-custom-fe-checkBoxIcon {
  background-image: url("../../../../../assets/icons/svg/CheckBoxIcon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}

.e-custom-fe-dateTimeIcon {
  background-image: url("../../../../../assets/icons/svg/DateTimeIcon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}

.e-custom-fe-lookUpIcon {
  background-image: url("../../../../../assets/icons/svg/LookUpIcon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}

.e-custom-fe-numberIcon {
  background-image: url("../../../../../assets/icons/svg/NumericIcon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}

.e-custom-fe-userIcon {
  background-image: url("../../../../../assets/icons/svg/UserProfile.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}

.e-custom-fe-uploadIcon {
  background-image: url("../../../../../assets/icons/svg/upload.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}

.e-custom-fe-imageIcon {
  background-image: url("../../../../../assets/icons/svg/image.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px !important;
  height: 20px !important;
}
