.root {
  top: 0;
  background: red;
  z-index: 99999;
  width: 100%;
  color: white;
  text-align: center;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: auto;
}
