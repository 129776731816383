.controlSection {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.displayFlexAlignCenter {
  display: flex;
  align-items: center;
}

.mr15 {
  margin-right: 15px;
}

.displayFlexJustifyCenter {
  display: flex;
  justify-items: center;
}

.displayFlexJustifySpaceBetween {
  display: flex;
  justify-content: space-between;
}

.dFlex {
  display: flex;
}

.ml15 {
  margin-left: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr10 {
  margin-right: 10px;
}

.mt15 {
  margin-top: 15px;
}

.fontWeight600 {
  font-weight: 600;
}
