.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  gap: 54px;
  align-items: center;
}

.image {
  width: fit-content;
  margin-right: -150px;
}

.welcomeText {
  display: flex;
  gap: 12px;
  flex-direction: column;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 68px;
  width: 100%;
  align-items: center;
}

.welcomeHeader {
  font-weight: 700;
  font-size: 36px;
}

.welcomeDescription {
  font-weight: 700;
  font-size: 24px;
}
