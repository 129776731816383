:root {
  --aspect-ratio-1-dot-2: 1.2;
  --aspect-ratio-3: 3;
  --size-neg-10: -0.625rem;
  --size-neg-4: -0.25rem;
  --size-neg-2: -0.125rem;
  --size-neg-3: -0.1875rem;
  --size-neg-1: -0.0625rem;
  --size-neg-7: -0.4375rem;
  --size-neg-27: -1.6875rem;
  --size-neg-23: 1.4375rem;
  --size-0: 0rem;
  --size-1: 0.0625rem;
  --size-1-dot-5: 0.09375rem;
  --size-0-dot-five: 0.03125rem;
  --size-2: 0.125rem;
  --size-3: 0.1875rem;
  --size-4: 0.25rem;
  --size-5: 0.3125rem;
  --size-6: 0.375rem;
  --size-7: 0.4375rem;
  --size-8: 0.5rem;
  --size-9: 0.5625rem;
  --size-10: 0.625rem;
  --size-11: 0.6875rem;
  --size-12: 0.75rem;
  --size-13: 0.8125rem;
  --size-14: 0.875rem;
  --size-15: 0.9375rem;
  --size-16: 1rem;
  --size-17: 1.0625rem;
  --size-18: 1.125rem;
  --size-19: 1.1875rem;
  --size-20: 1.25rem;
  --size-21: 1.3125rem;
  --size-22: 1.375rem;
  --size-23: 1.437rem;
  --size-24: 1.5rem;
  --size-25: 1.5625rem;
  --size-26: 1.625rem;
  --size-27: 1.6875rem;
  --size-28: 1.75rem;
  --size-30: 1.875rem;
  --size-31: 1.9375rem;
  --size-32: 2rem;
  --size-34: 2.125rem;
  --size-35: 2.188rem;
  --size-33: 2.0625rem;
  --size-35: 2.1875rem;
  --size-36: 2.25rem;
  --size-38: 2.375rem;
  --size-40: 2.5rem;
  --size-42: 2.625rem;
  --size-43: 2.6875rem;
  --size-45: 2.813rem;
  --size-48: 3rem;
  --size-49: 3.0625rem;
  --size-50: 3.125rem;
  --size-51: 3.1875rem;
  --size-52: 3.25rem;
  --size-55: 3.4375rem;
  --size-56: 3.5rem;
  --size-58: 3.625rem;
  --size-60: 3.75rem; 
  --size-62: 3.875rem;
  --size-63: 3.9375rem;
  --size-64: 4rem;
  --size-65: 4.0625rem;
  --size-66: 4.125rem;
  --size-67: 4.1875rem;
  --size-68: 4.25rem;
  --size-69: 4.3125rem;
  --size-70: 4.375rem;
  --size-73: 4.5625rem;
  --size-72: 4.5rem;
  --size-74: 4.625rem;
  --size-75: 4.6875rem;
  --size-76: 4.75rem;
  --size-80: 5rem;
  --size-81: 5.0625rem;
  --size-84: 5.25rem;
  --size-85: 5.3125rem;
  --size-87: 5.4375rem;
  --size-90: 5.625rem;
  --size-95: 5.938rem;
  --size-94: 5.875rem;
  --size-96: 6rem;
  --size-100: 6.25rem;
  --size-101: 6.3125rem;
  --size-103: 6.4375rem;
  --size-108: 6.75rem;
  --size-110: 6.875rem;
  --size-120: 7.5rem;
  --size-127: 7.938rem;
  --size-130: 8.125rem;
  --size-179: 11.1875rem;
  --size-140: 8.75rem;
  --size-142: 8.875rem;
  --size-150: 9.375rem;
  --size-160: 10rem;
  --size-186: 11.625rem;
  --size-199: 12.438rem;
  --size-200: 12.50rem;
  --size-210: 13.125rem;
  --size-212: 13.25rem;
  --size-220: 13.75rem;
  --size-226: 14.125rem;
  --size-230: 14.375rem;
  --size-250: 15.625rem;
  --size-260: 16.25rem;
  --size-283: 17.688rem;
  --size-240: 15rem;
  --size-300: 18.75rem;
  --size-310: 19.375rem;
  --size-360: 22.5rem;
  --size-365: 22.8125rem;
  --size-400: 25rem;
  --size-410: 25.625rem;
  --size-440: 27.5rem;
  --size-431: 26.9375rem;
  --size-450: 28.125rem;
  --size-480: 30rem;
  --size-498: 31.125rem;
  --size-500: 31.25rem;
  --size-530: 33.125rem;
  --size-550: 34.375rem;
  --size-600: 37.5rem;
  --size-650: 40.625rem;
  --size-783: 48.9375rem;
  
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  --font-weight-bold: bold;
}