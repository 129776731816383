.sidebar {
  position: relative;
  width: var(--size-80);
  float: left;
  display: flex;
  background: #eff5f9 !important;
  height: 100%;
  transition: 0.3s;
}

.bottomMenu {
  display: flex;
  flex-direction: column;
}

.iconWrapperSelected {
  display: flex;
  gap: var(--size-18);
  cursor: pointer;
  align-items: center;
}

.midHorizontalLineCollapsed {
  height: var(--size-35);
  cursor: pointer;
  border-right: var(--size-2) solid var(--text-light);
  margin-right: 13px;
  opacity: 0.3;
  position: absolute;
  top: 50%;
  bottom: 50;
  bottom: 50%;
  padding-left: var(--size-8);
  z-index: 1;
  right: var(--size-2);
}

.midHorizontalLineCollapsed:hover {
  cursor: pointer;
  border: none;
  background-repeat: no-repeat;
  opacity: 1;
  background-image: url("../../assets/icons/svg/BigLeftArrow.svg");
}

.expanded {
  width: var(--size-250);
}

.collapsed {
  width: var(--size-80);
}

.iconWrapperNotSelected {
  cursor: pointer;

  /* margin: var(--size-24) 0 var(--size-25) var(--size-25); */
  display: flex;
  gap: var(--size-15);
}

.activeIndicator {
  width: var(--size-5);
  height: var(--size-9);
  background-image: linear-gradient(#6bbbe3, #3e8ab0);
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.line {
  height: var(--size-1);
  flex-grow: 0;
  opacity: 0.3;
  background-color: var(--primary-gradient-color1);
  margin: 0 var(--size-12);
}

/* .middleIcons {
  height: 100%;
  overflow: hidden;
  width: 100%;
} */

/* .middleIcons::-webkit-scrollbar {
  display: none;
} */

.lowerFixedIcons {
  /* padding-top: var(--size-20); */
}

.upperFixedIcons {
  /* padding-bottom: var(--size-20); */
}

.allIconsWrapper {
  /* height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%; */
}

/* .middleIconsWrapper {
  height: 100%;
  overflow: hidden;
  display: flex;
}

.middleIconsWrapper::-webkit-scrollbar {
  width: var(--size-3);
  display: none;
  background-color: var(--surface-stroke-gradient-color2);
} */

.horizontalLineWrapper {
  height: 100%;
  display: flex;
  align-items: center;
  width: var(--size-23);
  justify-content: center;
}

.midHorizontalLineExpanded {
  padding-right: var(--size-8);
  height: var(--size-35);
  cursor: pointer;
  border-left: var(--size-2) solid var(--text-light);
  opacity: 0.3;
  z-index: 11;
  top: 50%;
  position: absolute;
  bottom: 50%;
  left: var(--size-65);
}

.midHorizontalLineExpanded:hover {
  cursor: pointer;
  border: none;
  opacity: 1;
  background-repeat: no-repeat;
  background-image: url("../../assets/icons/svg/BigRightArrow.svg");
}

.descriptionClass {
  transition: 0.3s;
  font-size: var(--size-14);
}

.activeClass {
  background: linear-gradient(100.44deg, #6bbbe3 0%, #3e8ab0 110.4%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.upArrowClass {
  text-align: center;

  /* padding-top: var(--size-15);
  padding-right: var(--size-8); */
  cursor: pointer;

  /* padding-bottom: var(--size-20); */
  display: flex;
  justify-content: center;
}

.downArrowClass {
  text-align: center;

  /* padding-bottom: var(--size-15); */
  cursor: pointer;

  /* padding-right: var(--size-8); */

  /* padding-top: var(--size-20); */
  display: flex;
  justify-content: center;
}

.iconDivNotSelected {
  display: flex;
  gap: var(--size-15);

  /* margin: var(--size-32) var(--size-24); */
  cursor: pointer;
  align-items: center;
}

.iconDivSelected {
  display: flex;
  gap: var(--size-15);

  /* margin: var(--size-24) 0 var(--size-25) 0; */
  cursor: pointer;
  align-items: center;
}

.settingSelected {
  margin: var(--size-24) 0 var(--size-25) 0;
}

.settingNotSelected {
  margin: var(--size-24) 0 var(--size-25) var(--size-25);
}
