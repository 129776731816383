* {
    font-family: "Source Sans Pro", sans-serif;
    margin: 0;
    padding: 0;
}

.e-control, .e-toolbar-items .e-tbar-btn-text {
    font-family: "Source Sans Pro", sans-serif !important;
}

.e-dashboardlayout.e-control .e-panel {
    z-index: 1 !important;
}

/*.e-search-wrap {
    display: none;
}*/

.e-headercell,
.e-detailheadercell {
    background-color: #e5e5e5 !important;
}

.e-grid .e-headercelldiv {
    font-weight: bold;
}

.e-grid .e-rhandler.e-rcursor {
    border-color: lightgrey !important;
}

.text-gray {
    color: var(--text-light);
}

.e-control.e-rte-readonly {
    border: none;
}

input::placeholder {
    color: var(--disabled-text-gray) !important;
    font-size: var(--size-14);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--light-blue);
    border-radius: var(--size-10);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--text-light);
}

.justify-content-center {
    justify-content: center;
}

.flex-1 {
    flex: 1;
}

.attachedFilesList .e-chip-list .e-chip .e-chip-avatar {
    background-color: transparent !important;
}

.attachedFilesList .e-chip-list .e-chip {
    border-radius: var(--size-5);
    line-height: unset !important;
    border: none !important;
    height: var(--size-34) !important;
    padding-left: var(--size-15);
    background-color: var(--button-light);
    color: var(--primary-text) !important;
}

.attachedFilesList .e-chip-list .e-chip-delete.e-dlt-btn::before {
    content: "";
    background-image: url("../icons/svg/close.svg");
    width: var(--size-10);
    height: var(--size-10);
    background-size: var(--size-10);
    background-repeat: no-repeat;
}

#charts4AxisGroup0Inside,
#charts1AxisGroup0Inside,
#charts2AxisGroup0Inside,
#charts3AxisGroup0Inside,
#charts4_ChartAreaBorder,
#charts3_ChartAreaBorder,
#charts2_ChartAreaBorder,
#charts1_ChartAreaBorder {
    opacity: 0;
}

#charts4AxisGroup1Inside,
#charts3AxisGroup1Inside,
#charts2AxisGroup1Inside,
#charts1AxisGroup1Inside {
    opacity: 0;
}

#charts1_Series_0 {
    stroke: #f0b47d;
}

#charts2_Series_0 {
    stroke: #f09d9d;
}

#charts3_Series_0 {
    stroke: #7ac5dc;
}

#charts4_Series_0 {
    stroke: #99e3c5;
}

.text-2xs {
    font-size: var(--size-9);
}

.shadow-xl {
    box-shadow: var(--size-0) var(--size-20) var(--size-40) var(--shadow-with-opacity);
}

.shadow-lg {
    box-shadow: var(--size-4) var(--size-4) var(--size-15) var(--box-shadow-lg);
}

.shadow-sm {
    box-shadow: var(--size-2) var(--size-2) var(--size-4) var(--button-hover-shadow);
}

.shadow-xs {
    box-shadow: var(--size-0) var(--size-0) var(--size-10) var(--box-shadow-lg);
}