:root {
  --primary-blue:#d4e8fa;
  --primary-gradient-color1: rgba(107, 187, 227);
  --primary-gradient-withOpacity:rgba(107, 187, 227, 0.6);
  --primary-gradient-color2: #3e8ab0;
  --primary-blue: #D4E8FA;
  --card-hover-color:rgba(202,236,255,0.4);
  --card-selection-color:rgba(202,236,255);
  --primary-gradient-color2-opacity: #3e8ab045;
  --info-light-blue: #c7e0f4;
  --button-light: #ecf3fe;
  --info-lighter-blue: #deecf9;
  --light-blue: #c6d0df;
  --light-blue-tag: #ECF3FE;
  --light-black: #333;
  --primary-text: #192048;
  --center-border: #212121;
  --lighter-gray: #eaeaea;
  --soft-gray: #c8c8c8;
  --text-gray: #667085;
  --box-shadow-lg: rgba(65, 92, 171, 0.15);
  --button-hover-shadow: rgba(25, 32, 72, 0.15);
  --disabled-text-gray: rgba(25, 32, 72, 0.5);
  --background-scrim:rgba(25, 32, 72, 0.6);
  --split-btn-opacity-hover: rgba(25, 32, 72, 0.2);
  --shadow-with-opacity: rgba(106, 138, 177, 0.2);
  --gradient-color: #6bbbe3;
  --disabled-border: #f4f4f4;
  --inner-border: #7bd2fc;
  --surface-white: #ffffff;
  --border-gray: #d8d8d8;
  --natural-gray: #898ea3;
  --positive-communication: #45cf80;
  --warning: #ffcf55;
  --negative-communication: #e75555;
  --surface-stroke-gradient-color1: #ecf3fe;
  --surface-stroke-gradient-color2: rgb(236, 243, 254, 0.15);
  --surface-gradient-color1: #ffffff;
  --surface-gradient-color2: #f0f5f9;
  --light-gray: #e2e9f3;
  --gray: #BCBCBC;
  --surface-neutral: #f0f5f9;
  --surface-black: #191b22;
  --box-shadow: rgba(0, 0, 0, 0.15);
  --text-light: #666a84;
  --white-opacity: rgba(255, 255, 255, 0.5);
  --natural-white: rgba(255 255 255);
  --card-box-shadow: rgba(65, 92, 171, 0.15);
  --selection: #caecff;
  --system-colour-1: #1080d6;
  --system-colour-2: #38ba84;
  --system-colour-3: #e75555;
  --system-colour-4: #6895b6;
  --system-colour-5: #86b785;
  --system-colour-6: #cb4444;
  --system-colour-7: #45cf80;
  --system-colour-8: #e75555;
  --system-colour-9: #ffcf55;
  --system-colour-10: #45cf80;
  --system-colour-11: #e75555;
  --system-colour-12: #ffcf55;
  --system-colour-13: #624fff;
  --system-colour-14: #b8ce1b;
  --system-colour-15: #1ec7f8;
  --system-colour-16: #5b5496;
  --system-colour-17: #6faa8e;
  --system-colour-18: #5bb8ff;
  --system-colour-19: #ff5b1b;
  --system-colour-20: #d4066d;
  --system-colour-21: #0570ab;
  --system-colour-22: #ec9e80;
  --system-colour-23: #bb598a;
  --system-colour-24: #7d7d7d;
  --system-colour-25: #3587f8;
  --system-colour-26: #fecd04;
  --system-colour-27: #ff6d6f;
  --system-colour-28: #607b9f;
  --system-colour-29: #3b997e;
  --system-colour-30: #d27373;
  --system-colour-31: #8a60f7;
  --system-colour-32: #f7156d;
  --system-colour-33: #fe19b2;
  --system-colour-34: #7b5dc8;
  --system-colour-35: #b256a9;
  --system-colour-36: #bb4dff;
  --system-colour-37: #19204880;
  --system-colour-38:  #0071ba;
  --system-colour-39:  #fefefe;
  --system-colour-40: #3b405c;
  --system-colour-41: #192048;
  --system-colour-42: #e0e0e0;
  --system-colour-41:#f6f9fc;
  --black-text-colour:#141313;
  --system-colour-43 : #fff;
  --system-colour-44: #ecf3fe;
  --system-colour-45: #0006A2;
  --system-colour-46: #D1DBEB;
  --neutral-black:#222530;
}