.heading {
  font-style: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.chevronRightIcon {
  display: flex;
}

.element {
  color: #192048 !important;
}

.boldClass {
  font-weight: bold;
}

.ellipsis {
  flex-wrap: nowrap;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
