.root {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  backdrop-filter: blur(16px);
  align-items: center;
  justify-content: center;
}
