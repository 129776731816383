.box {
  background: #fff;
  box-shadow: 4px 4px 15px rgb(65 92 171 / 15%);
  border-radius: 10px;
  width: 240px;
  height: 204px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  cursor: pointer;
}

.root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.welcomeText {
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-items: center;
  padding: 24px 0;
}

.boxWrapper {
  display: flex;
  width: 80%;
  justify-content: space-evenly;
}

.boxImage {
  width: fit-content;
}

.welcomeHeader {
  font-weight: 700;
  font-size: 36px;
}

.welcomeDescription {
  font-weight: 600;
  font-size: 24px;
}

.boxText {
  padding: 0 30px;
  text-align: center;
}

.bottomTextContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: var(--size-14);
  justify-content: center;
  align-items: center;
}

.enterprisePlanText {
  color: var(--primary-gradient-color1);
  font-weight: 600;
}
