.headerWrapper {
  position: sticky;
  top: 0;
  background: #f0f5f9;
  z-index: 10;
}

.zIndex1 {
  z-index: 1 !important;
}

.root {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 75px;
  align-items: center;
  margin: 0;
}

.leftSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rightSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.headerElement {
  padding: 16px;
}

.element {
  padding: var(--size-20);
  height: 100%;
}

.logoBox {
  width: var(--size-76);
  display: flex;
  align-items: center;
  height: 100%;
}

.logo {
  width: 100%;
}

.notificationsIcon {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.SearchIcon {
  cursor: pointer;
  padding-right: 10px !important;
  color: #757575;
  align-items: center;
  display: flex;
}

.logout {
  color: #757575;
  padding-left: 10px;
  cursor: pointer;
}

.profilePicture {
  color: #757575;
  display: flex;
  align-items: center;
}

.portalSettings {
  cursor: pointer;
}

.portalSettingsWrapper {
  color: #757575;
}

.breadCrumbWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--size-20);
  align-items: center;
}

.profileDropdownBackdrop {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: right;
  background-color: var(--background-scrim);
  z-index: 25;
  cursor: pointer;
}

.profileDropdown {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: right;
  background-color: #fff;
  border-radius: 6px;
  padding: var(--size-20) var(--size-30) var(--size-20) var(--size-30);
  z-index: 25;
  overflow: auto;
  box-shadow: 5px 10px 10px 0 rgb(0 0 0 / 15%);
  position: absolute;
  top: var(--size-30);
  right: var(--size-30);
  bottom: var(--size-30);
}

.profileDropdownDark {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: right;
  background-color: var(--surface-black);
  border-radius: 6px;
  padding: var(--size-20) var(--size-30) var(--size-20) var(--size-30);
  z-index: 25;
  overflow: auto;
  box-shadow: 5px 10px 10px 0 rgb(0 0 0 / 15%);
  position: absolute;
  top: var(--size-30);
  right: var(--size-30);
  bottom: var(--size-30);
}

.profileDropdownContent {
  height: 100%;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
}

.profileDropdownContentDark {
  height: 100%;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-bottom: solid 1px rgb(236 243 254 / 25%);
}

.profileDropdownContentInfo {
  /* height: 100%; */
  width: inherit;
  display: flex;
  gap: 40px;
}

.profileDropdownContentDetails {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.linksSection {
  height: 50%;
  width: 100%;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(2, minmax(30px, 180px));
  text-align: center;
  grid-column: 10px;
  align-items: center;
  border-bottom: 1px solid rgb(0 0 0 / 15%);
}

.linksSectionDark {
  height: 50%;
  width: 100%;
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: repeat(2, minmax(30px, 180px));
  text-align: center;
  grid-column: 10px;
  align-items: center;
  border-bottom: solid 1px rgb(236 243 254 / 25%);
}

.signoutSection {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoSidebarCompany {
  width: 70px;
  height: 70px;
  flex-grow: 0;
  border-radius: 100px;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 25%);
  object-fit: scale-down;
}

.logoSidebarOther {
  width: 80px;
  height: 80px;
  flex-grow: 0;
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
  object-fit: scale-down;
}

.contentmainText {
  font-size: 16px;
  font-weight: 600;
}

.nameClass {
  display: flex;
  gap: var(--size-5);
  align-items: center;
}

.imgClass {
  height: var(--size-20);
  display: flex;
  align-items: center;
}

.contentgenralText {
  font-size: var(--size-14);
}

.logOutBtn {
  padding: 7px 24px 7px 21px;
  border-radius: 5px;
  background-color: #eaeaea;
  width: 97px;
  height: 32px;
  border: 0;
  overflow: hidden;
  font-size: var(--size-14);
  font-weight: 600;
  margin-bottom: 10px;
}

.remSpace {
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.remSpaceItem {
  width: inherit;
  font-size: var(--size-14);
  color: #bababa;
  border-top: 1px solid rgb(0 0 0 / 15%);
  padding-top: 10px;
}

.avatarInitialsWrapper {
  background: green;
  color: white;
  border-radius: 50px;
  width: 75px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.avatarInitialsSmall {
  background: green;
  color: white;
  border-radius: 31px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.link {
  cursor: pointer;
}

.suggestion {
  position: absolute;
  right: 24px;
  width: max-content;
  display: flex;
  justify-content: flex-end;
  font-size: var(--size-14);
  background: red;
  color: white;
  padding: 0 12px;
  border-radius: 12px;
}

.profileLinks {
  display: flex;
  gap: 15px;
}

.redirectLink:hover {
  color: var(--gradient-color);
  cursor: pointer;
  font-weight: 600;
}

.boldClass {
  font-weight: bold;
  color: var(--text-light);
}
