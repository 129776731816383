:root {
  --border-solid: solid;
  --transparent: transparent;
  --not-allowed: not-allowed;
  --padding-box: padding-box;
  --none: none;
  --text: text;
  --align-center: center;
  --bg-position-center: center;
  --display-flex: flex;
  --display-grid: grid;
  --display-block: block;
  --position-relative: relative;
  --display-inline-block: inline-block;
  --display-inline-flex: inline-flex;
  --position-absolute: absolute;
  --border-box: border-box;
  --justify-space-between: space-between;
  --justify-content-left: left;
  --font-style-normal: normal;
  --background-no-repeat: no-repeat;
  --position-fixed: fixed
}