.e-dashboardlayout.e-control .e-panel .e-panel-container .e-panel-content {
  overflow: hidden;
}

.e-panel-header {
  background: #f7f7f7;
}

.e-grid .e-gridheader {
  border-top: none;
}
