.cube-slick-grid .slickgrid-container .slick-row.odd {
  background: none !important;
}

.cube-slick-grid .slickgrid-container .grid-canvas .slick-cell {
  display: flex;
}

.cube-slick-grid .slickgrid-container .slick-row:hover {
  background: var(--system-colour-46) !important;
}

.cube-slick-grid .slickgrid-container .grid-canvas .slick-cell.selected {
  background-color: var(--primary-blue) !important;
}

.cube-slick-grid .slickgrid-container .slick-header-columns .slick-header-column.slick-state-default {
  background-color: var(--surface-white) !important;
  background-image: linear-gradient(101deg, var(--surface-stroke-gradient-color1) 29%, rgb(236 243 254 / 15%) 111%) !important;
}

.cube-slick-grid .slickgrid-container .slick-pane-top {
  border: none !important;
}

.cube-slick-grid .slickgrid-container {
  border: none !important;
}

.cube-slick-grid .slick-preheader-panel .slick-dropzone,
.cube-slick-grid .slick-preheader-panel .slick-dropzone-hover,
.cube-slick-grid .slick-topheader-panel .slick-dropzone,
.cube-slick-grid .slick-topheader-panel .slick-dropzone-hover {
  border: none !important;
}

.cube-slick-grid .slick-cell-checkboxsel .icon-checkbox-container div.mdi,
.cube-slick-grid .slick-column-name .icon-checkbox-container div.mdi,
.cube-slick-grid .slick-headerrow-column.checkbox-header .icon-checkbox-container div.mdi {
  background: linear-gradient(100.44deg, var(--primary-gradient-color1) 0%, var(--primary-gradient-color2) 110.4%) !important;
}

.cube-slick-grid .slickgrid-container .slick-row {
  border-bottom: 1px solid var(--system-colour-44) !important;
}

.cube-slick-grid .slickgrid-container .slick-cell {
  /* border-right: 1px solid var(--system-colour-44) !important; */
}

.cube-slick-grid .slickgrid-container .slick-group-toggle {
  /* border-right: 1px solid var(--system-colour-44) !important; */
}

.search-filter input,
input.search-filter {
  font-family: "Source Sans Pro" !important;
}

.cube-slick-grid .slickgrid-container .grid-canvas .slick-cell .slick-group-toggle {
  color: #333;
}

.cube-slick-grid .slick-preheader-panel .slick-dropzone .slick-group-toggle-all .slick-group-toggle-all-icon,
.slick-preheader-panel .slick-dropzone-hover .slick-group-toggle-all .slick-group-toggle-all-icon,
.slick-topheader-panel .slick-dropzone .slick-group-toggle-all .slick-group-toggle-all-icon,
.slick-topheader-panel .slick-dropzone-hover .slick-group-toggle-all .slick-group-toggle-all-icon {
  color: #333;
}

.cube-slick-grid .slickgrid-container .slick-preheader-panel.slick-state-default .slick-header-columns .slick-header-column,
.slickgrid-container .slick-topheader-panel.slick-state-default .slick-header-columns .slick-header-column {
  border-right-width: 0 !important;
}
