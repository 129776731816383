.root {
  position: absolute;
  bottom: calc(var(--size-36) / var(--aspect-ratio-3));
  right: var(--size-36);
  z-index: 10;
}

.mainContainer {
  flex-direction: column;
  overflow-x: hidden;
  border-radius: 5px;
  box-shadow: 4px 4px 15px 0 rgb(65 92 171 / 15%);
  border: solid 1px var(--primary-blue);
  background-image: linear-gradient(#fff, #f0f5f9);
  width: var(--size-300);
  max-height: calc(var(--size-300) / var(--aspect-ratio-1-dot-2));
  margin-bottom: var(--size-15);
  margin-right: var(--size-20);
}

.header {
  display: inline-flex;
  gap: var(--size-120);
  width: var(--size-300);
  flex-direction: row;
  background-color: #f0f5f9;
  height: var(--size-60);
  text-align: center;
  position: sticky;
  top: 0;
}

.openFile {
  margin-left: var(--size-25);
  font-size: var(--size-18);
  margin-top: var(--size-20);
  color: #192638;
}

.closeFile {
  font-size: var(--size-12);
  margin-top: var(--size-26);
  color: #3e8ab0;
  cursor: pointer;
}

.flexRowStart {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fileDiv {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 4;
}

.fileDetails {
  display: flex;
  background-color: #fff;
  overflow: auto;
}

.fileDetails:hover {
  background-color: #caecff;
}

.fileName {
  margin-top: var(--size-8);
  margin-bottom: var(--size-10);
  flex: 1;
  word-break: break-all;
}

.fileSideIcon {
  flex: 1;
  margin-left: var(--size-20);
  margin-top: var(--size-5);
}

.bgIcon {
  width: var(--size-52);
  height: var(--size-52);
  cursor: pointer;
  position: relative;
  float: right;
}

.closeIconDiv {
  flex: 1;
}

.closeIcon {
  width: var(--size-9);
  height: var(--size-9);
  cursor: pointer;
}
