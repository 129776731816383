.subMenu {
  position: absolute;
  bottom: 4.4rem;
  display: grid;
  background-color: white;
  grid-template-columns: 1fr;
  box-shadow: 0 0 6px rgb(0 0 0 / 15%);
  padding: 0.3rem;
  gap: 0.25rem;
}

.subMenu::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  left: 50%;
  bottom: 6px;
  border: 6px solid transparent;
  border-bottom: 0;
  border-top: 12px solid white;
  transform: translate(-50%, calc(100% + 5px));
}

.subMenuhorizontal {
  position: absolute;
  display: flex;
  background-color: white;
  flex-direction: row;
  box-shadow: 0 0 6px rgb(0 0 0 / 15%);
  padding: 0.3rem;
  gap: 0.25rem;
  column-gap: 10px;
  right: 3.5rem;
}

.tooltip {
  display: flex;
}

.hiddenTooltip {
  display: none !important;
}
