.root {
  display: flex;
  justify-content: space-evenly;
  padding: 24px;
  background: transparent;
  color: black;
  gap: 16px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.name {
  font-size: 24px;
  font-weight: 600;
}

.description {
  font-size: 14px;
}

.product {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 8px;
  box-shadow: 4px 4px 15px 0 rgb(65 92 171 / 15%);
  background-color: #fff;
  padding: 32px;
}

.priceWrapper {
  font-size: 36px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 6px;
}

.recurringFrequency {
  opacity: 0.7;
  font-size: 14px;
  font-weight: normal;
}

.quantityWrapper {
  flex-direction: column;
  gap: 16px;
  display: flex;
  align-items: stretch;
}

.featuresWrapper {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(128deg, #ecf3fe 22%, rgb(236 243 254 / 15%) 108%);
  border-image-slice: 1;
  background-image: linear-gradient(124deg, #fff 45%, #f0f5f9 129%), linear-gradient(128deg, #ecf3fe 22%, rgb(236 243 254 / 15%) 108%);
  padding: 32px 16px;
  font-size: 14px;
  color: #666a84;
  display: flex;
  gap: 16px;
  flex-direction: column;
  height: 100%;
}

.featuresList {
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.individualFeature {
  display: flex;
  gap: 8px;
}
