.noNotificationContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.noNotificationIcon {
  width: 100px;
  height: 100px;
  fill: #a9dff9;
  margin: auto;
}

.noNotificationTitle {
  text-align: center;
  margin-top: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.userMessage p,
.userMessage strong {
  display: inline;
}

.msgContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 100%;

  /* max-height: 452px; */
}

.earlierMessage {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 600px;
  max-height: 200px;
}

.new {
  background: #eaeaea;
  color: #575656 !important;
  flex-direction: row;
  padding: 5px;
}

.moreNotifications {
  color: #575656 !important;
  flex-direction: row;
  padding: 5px;
}

.moreNotifications a {
  color: inherit;
}

.newTitle {
  margin-left: 1rem;
}

.proPic {
  border-radius: 50%;
  width: 10%;
  margin: 0 15px;
}

.userMessage {
  display: grid;
  grid-template-columns: auto 1fr; /* Allow the message to take up the remaining space */
  justify-content: space-between;
  align-items: center; /* Center content vertically */
}

.userMessage p,
.userMessage strong {
  display: inline;
  white-space: normal; /* Allow text to wrap */
  overflow: visible; /* Make sure overflowing text is visible */
}

.message {
  font-size: 14px;
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  white-space: normal; /* Allow text to wrap */
  word-wrap: break-word;/* Break words that are too long to fit  */
}

.duration {
  text-align: end;
  display: block;
  color: #666a84;
  font-size: 12px;
  width: 121px;
}

.viewAllTitleDiv {
  text-align: center;
  position: sticky;
  bottom: 0;
  background-color: white;
  padding: 16px 32px;
}

.viewAllTitle,
.markAllRead {
  color: #6bbbe3;

  /* text-decoration: underline !important; */
  size: 16px;
  font-weight: 700;
}

.markAllRead {
  color: blue;
  text-decoration: underline !important;
  position: absolute;
  right: 1vw;
}

.notification:hover,
.notification {
  cursor: pointer;
  color: #757575;
}

.notificationCount {
  width: 20px;
  height: 20px;
  position: absolute;
  background-image: linear-gradient(127deg, #6bbbe3 0%, #3e8ab0 111%);
  box-shadow: 0 4px 4px 0 rgb(139 139 139 / 10%);
  color: white;
  font-size: 12px;
  border-radius: 60px;
  padding: 4px;
  margin-left: 13px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
