.root {
  position: absolute;
  bottom: calc(var(--size-36) / var(--aspect-ratio-3));
  right: var(--size-36);
  z-index: 10;
}

.mainBox {
  display: flex;
  flex-direction: column;
  max-height: var(--size-120);
  width: var(--size-400);
  margin-bottom: var(--size-20);
  border: 1px solid #ddd;
  padding: 24px;
  background-color: #fff;
  box-shadow: rgb(65 92 171 / 15%);
}

.upperBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.uploadInfo {
  font-size: var(--size-24);
  margin: 10px;
}

.iconsDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: var(--size-18);
}

.icons {
  height: var(--size-20);
  width: var(--size-20);
  cursor: pointer;
}

.progressBarContainer {
  background-color: #d4d1d1;
  width: 100%;
  border-radius: 20px;
  height: 2px;
}

.progressBar {
  background-color: #0078d6;
  height: 2px;
  margin-bottom: var(--size-10);
}

.fileImage {
  width: var(--size-60);
  height: var(--size-60);
}

.progressWrapper {
  position: relative;
  width: 70%;
  padding-top: 12px;
}

.progressBody {
  background-color: #d4d1d1;
  width: 100%;
  border-radius: 20px;
  height: 2px;
}

.progressArea {
  background-color: #0078d6;
  height: 2px;
}

.fileUploadStatusWrapper {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 4px;
  font-size: 11px;
}

.uploadingFiles {
  position: relative;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 100;
  padding: 24px;
  overflow-y: auto;
  width: var(--size-400);
  max-height: var(--size-480);
  box-shadow: rgb(65 92 171 / 15%);
}

.fileDetails {
  border: 1px solid #ecf3ec;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: var(--size-16);
  background-color: #fff;
}
